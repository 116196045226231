import React from "react";
import * as styles from "./index.module.scss";
import { Typography, PageLayout, TypographyV2 } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import Margins from "@components/Margins/Margins";



export default function DPA() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "dpa",
			}}
		>
			<Margins className={styles.margins}>
				<BackgroundFadeContainer
					type="dpaBlue"
					className={styles.wrapper}
				>
					<Typography className={styles.pageTitle}>
						Data Processing Addendum
					</Typography>
				</BackgroundFadeContainer>
			</Margins>
			<div className={styles.commonPricingComponentContainer}>
				<ContentDPA />
			</div>
		</PageLayout>
	);
}

export const ContentDPA = () => {
	return (
		<div className={styles.container}>
			<section>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					This Data Processing Addendum (“<b>DPA</b>”) is executed upon the
					date of the last signature (“<b>DPA Effective Date</b>”) by Genemod
					Corporation (“<b>Genemod</b>”) and the customer identified below
					(“<b>You</b>” or “<b>Customer</b>”) to amend the master subscription
					agreement governing the use of the Genemod Service by
					Customer (“<b>Agreement</b>”).
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					This DPA is incorporated into and subject to the Agreement
					(and the limitation of liabilities set forth therein shall
					apply to this DPA) and reflects the parties’ agreement with
					respect to the processing of personal data within Customer
					Data under the Agreement. If there is a conflict between
					this DPA and the Agreement, this DPA shall control.
					Capitalized terms not otherwise defined shall have the
					meanings ascribed to them in the Agreement.&nbsp;
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					To the extent applicable and required by Applicable Data
					Protection laws, the EEA Standard Contractual Clauses, set
					forth as Exhibit A, form an integral part of this DPA.&nbsp;
					Capitalized terms used but not defined in this DPA will have
					the meaning provided in the Agreement, or, when not defined
					in the Agreement, the meaning given to them under the GDPR.
					Except as modified below, the terms of the Agreement shall
					remain in full force and effect.&nbsp;
				</TypographyV2>
			</section>
			 
			<ul className={styles.ul_list} style={{ listStyleType: "none" }}>
				<li>
					<Typography className={styles.ul_title}>
						1. Definitions
					</Typography>
					<ul
						style={{
							listStyleType: "none",
							paddingLeft: "0px !important",
						}}
					>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.1 “Applicable Data Protection Law(s)”
									&nbsp;
								</TypographyV2>
								means the privacy, data protection, and data
								security laws and regulations of any
								jurisdiction applicable to the Processing of
								Personal Information under the Agreement,
								including, without limitation, the GDPR, the UK
								GDPR, and the CCPA. Where a specific reference
								is made to the GDPR, it shall be understood to
								be referring to the equivalent requirement under
								the UK GDPR, mutatis mutandis.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.2 “CCPA” &nbsp;
								</TypographyV2>
								means the California Consumer Privacy Act of
								2018 and any regulations promulgated thereunder,
								in each case, as amended from time to time,
								including the California Privacy Rights Act of
								2020 and any regulations promulgated thereunder.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.3 “Controller” &nbsp;
								</TypographyV2>
								means the natural or legal person, public
								authority, agency, or other body which, alone or
								jointly with others, determines the purposes and
								means of the Processing of Personal Information;
								where the purposes and means of such processing
								are determined by Union or Member State law, the
								controller or the specific criteria for its
								nomination may be provided for by Union or
								Member State law.
							</TypographyV2>
						</li>
						{/* <li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
									1.3 “Controller” &nbsp;
								</TypographyV2>
								means the natural or legal person, public
								authority, agency, or other body which, alone or
								jointly with others, determines the purposes and
								means of the Processing of Personal Information;
								where the purposes and means of such processing
								are determined by Union or Member State law, the
								controller or the specific criteria for its
								nomination may be provided for by Union or
								Member State law.
							</TypographyV2>
						</li> */}
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.4 “Data Subject” &nbsp;
								</TypographyV2>
								means an identifiable natural person who can be
								identified, directly or indirectly, in
								particular by reference to an identifier such as
								a name, an identification number, location data,
								an online identifier, or to one or more factors
								specific to the physical, physiological,
								genetic, mental, economic, cultural mor social
								identity of that natural person. In
								circumstances where the CCPA applies to
								Customer’s personally identifiable information,
								the term Data Subject has the same meaning as
								“consumer” under the CCPA, the term “controller”
								has the same meaning as “business” under the
								CCPA, and the term “Processor” has the same
								meaning as “service provider” under the CCPA.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.5 “EEA” &nbsp;
								</TypographyV2>
								means the European Economic Area.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.6 “GDPR” &nbsp;
								</TypographyV2>
								means Regulation (EU) 2016/679 of the European
								Parliament and of the Council of 27 April 2016
								on the protection of natural persons with regard
								to the processing of Personal Information and on
								the free movement of such data, and repealing
								Directive 95/46/EC (General Data Protection
								Regulation).
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.7 “Personal Data” &nbsp;
								</TypographyV2>
								means Customer Data that constitutes “personal
								data,” “personal information,” or “personally
								identifiable information” defined in Applicable
								Data Protection Laws, or information of a
								similar character regulated thereby, provided
								that such data is electronic data and
								information related to an identified or
								identifiable natural person and such data is
								Customer Data.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.8 “Processing” or “Process” &nbsp;
								</TypographyV2>
								means any operation or set of operations which
								is performed on Personal Data or on sets of
								Personal Data, whether or not by automated
								means.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.9 “Processor” &nbsp;
								</TypographyV2>
								means a natural or legal person, public
								authority, agency, or other body which processes
								Personal Information on behalf of the
								controller.
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.10 “Restricted Information” &nbsp;
								</TypographyV2>
								means (i) sensitive personal information as
								defined in Article 9 and 10 of the GDPR and
								other applicable data protection laws, and (ii)
								personal health information (meaning health or
								medical condition of an individual or the
								provision of health care to an individual).
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.11 “Standard Contractual Clauses” &nbsp;
								</TypographyV2>
								means Standard Contractual Clauses for the
								transfer of Personal Information to third
								countries pursuant to the GDPR and approved by
								European Commission Implementing Decision (EU)
								2021914 of 4 June 2021, currently located here:{" "}
								<a
									className={styles.link}
									target="_blank"
									href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj"
								>
									https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj.
								</a>
							</TypographyV2>
						</li>
						<li>
							<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper" className={styles.text_bold}>
									1.12 “UK GDPR” &nbsp;
								</TypographyV2>
								means the UK Data Protection Act 2018 as
								supplemented by Schedule 21, the Keeling
								Schedule.
							</TypographyV2>
						</li>
					</ul>
				</li>
				<li>
					<Typography className={styles.ul_title}>2. Term</Typography>
				</li>
			</ul>
			<section>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					This DPA will take effect on the DPA Effective Date and
					automatically terminate following the expiry or termination
					of the Agreement.&nbsp;
				</TypographyV2>

				<Typography className={styles.ul_title}>
					3. Information Security Program snd Related Matters
				</Typography>

				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod has implemented an information security program
					consisting of policies and procedures that define how system
					information is entered, managed, and protected. Genemod
					shall monitor, analyze, and respond to security incidents in
					a timely manner in accordance with Genemod’s standard
					operating procedure, which sets forth the steps that Genemod
					employees must take in response to a threat or security
					incident. Customer shall promptly apply any subscription
					service upgrade that Genemod determines is necessary to
					maintain the security, performance, or availability of the
					subscription service and shall, in general, comply with
					Genemod’s upgrade policy.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					4. Affected Persons/Categories of Data
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Users and business partner personnel designated by Customer
					are affected by the collection, processing, or use of
					Personal Information. The categories of Personal Data
					processed hereunder are specified in Annex 1 Appendix A.
					Unless otherwise agreed by the parties in writing, no
					Restricted Information shall be processed under the
					Agreement.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					5. Personnel
				</Typography>

				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod will ensure (a) that its personnel with access to
					Customer Data have committed themselves to confidentiality
					or are under an appropriate statutory obligation of
					confidentiality and (b) that such personnel are adequately
					instructed in the appropriate handling of Personal
					Information. Genemod shall implement measures to restrict
					access to Personal Information as set out in&nbsp; Annex II
					Appendix A.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					6. Audit Rights
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod makes available to the Customer all information
					necessary to demonstrate compliance with the obligations
					laid down in Article 28 GDPR, in particular, by making
					available compliance reports. &nbsp;In the event Customer
					wishes to carry out further audit activities, Customer shall
					provide Genemod and its subprocessors with as much notice as
					possible if it exercises any of its audit rights under this
					DPA or, as applicable, according to the Standard Contractual
					Clauses and shall pay Genemod and its subprocessors
					reasonable administrative costs and expenses for engaging
					and complying with any on-site audit, unless such audit
					shows that Genemod is in material breach with its
					obligations under this DPA.&nbsp;
				</TypographyV2>
				<Typography className={styles.ul_title}>
					7. Sub-Processing
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod uses sub-processors listed under&nbsp;
					<a
						className={styles.link}
						href="https://trust.genemod.net/subprocessors"
					>
						https://trust.genemod.net/subprocessors
					</a>
					. Customer hereby consents to Genemod engaging new
					subprocessors subject to Clause 9 of Appendix A and the
					following terms.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod shall provide Customer with thirty (30) days prior
					written notice (email being sufficient) of any new
					sub-processor. Customer shall notify Genemod promptly in
					writing within thirty (30) days after receipt of such
					notice, if the Customer has a reasonable basis to object to
					the use of a new sub-processor. For the avoidance of doubt,
					the Customer hereby acknowledges that the use of a new
					sub-processor shall be deemed acceptable if Genemod has
					procured: (i) the same level of protection of Personal Data
					by imposing the same obligations as set out in this DPA on
					each new sub-processor by way of a written contract; and
					(ii) that the relevant sub-processor will implement and use
					appropriate technical and organizational measures which meet
					the requirements of Applicable Data Protection Laws.
					Notwithstanding the foregoing, if Customer reasonably
					objects to the appointment of another sub-processor, the
					parties will come together in good faith to discuss an
					appropriate solution. Genemod may, in particular, choose:
					(a) not to use the intended sub-processor or (b) take
					corrective steps and/or measures reasonably requested by the
					Customer and engage the sub-processor. If the parties,
					acting in good faith, cannot agree to an appropriate
					solution, Customer may, in its sole discretion, terminate
					the Agreement.&nbsp;
				</TypographyV2>
				<Typography className={styles.ul_title}>
					8. Processing of Customer Data
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod will comply with the terms and conditions set forth
					in this DPA in its collection, receipt, transmission,
					storage, disposal, use, and disclosure of Personal Data and
					be responsible for the unauthorized collection, receipt,
					transmission, access, storage, disposal, use, and disclosure
					of Personal Data under its control or in its possession.
					&nbsp;Genemod will comply with all applicable federal and
					state privacy and data protection laws and regulations.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					With respect to Personal Data within Customer Data under
					this DPA, the parties agree that Customer is the Controller
					and Genemod is a Processor. Customer will comply with its
					obligations as a Controller, and Genemod will comply with
					its obligations as a Processor under the Agreement and this
					DPA. Genemod will only Process Customer Data in fulfilling
					its obligations under the Agreement, such as, without
					limitation, providing and supporting Customer’s usage of the
					subscription service, detecting, and addressing security and
					technical issues, and responding to support requests. The
					Processing of Customer Data only takes place within the
					framework of the Agreement and according to the instructions
					of Customer. In particular, the collected, processed, or
					used data may only be corrected, deleted, or blocked on
					instructions of Customer. Backup copies are created, if they
					are necessary to ensure proper data processing, or
					reproduction processes that are necessary in order to ensure
					compliance with regulatory retention requirements. All
					instructions must be issued in writing. Genemod shall
					immediately inform the Customer if, in Genemod’s opinion, an
					instruction violates the GDPR or other applicable data
					protection regulations.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					9. Data Subject Access Requests
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod will provide reasonable assistance to Customer in
					the fulfillment of Customer’s obligation to respond to Data
					Subject requests, referred to in Chapter 3 (Rights of the
					Data Subject) of the GDPR, for Personal Information stored
					and used to provide the Genemod Service. If a Data Subject
					raises a request directly with Genemod, Genemod will
					promptly pass this request to Customer.&nbsp;
				</TypographyV2>
				<Typography className={styles.ul_title}>
					10. Assistance, Reporting, and Impact Assessments
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod will provide reasonable assistance to Customer in
					complying with the obligations concerning the security of
					Personal Information, reporting requirements for data
					breaches, data protection impact assessments, and prior
					consultations, referred to in Articles 32 to 36 of
					Regulation (EU) 2016/679.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					11. Breach Notification
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Genemod shall report to Customer’s support contacts
					designated in Genemod’s customer support portal the
					accidental or unlawful destruction, loss, alteration,
					unauthorized disclosure of, or access to, Personal
					Information within Customer Data (“ <b>Breach</b> ”) within
					twenty-four (24) hours, after Genemod determines, or
					reasonably suspects, that a Breach has occurred, unless
					restricted by law. Accordingly, Genemod shall share
					information about the nature and consequences of the Breach
					that is reasonably requested by Customer to enable it to
					notify affected individuals, government agencies, and/or
					credit bureaus. Customer has sole control over the content
					of Customer Data that it enters into the subscription
					service and is solely responsible for determining whether to
					notify impacted Data Subjects and the applicable regulatory
					bodies or enforcement commissions and for providing such
					notice. Customer shall ensure that the support contacts
					designated in Genemod’s customer support portal be current
					and ready to receive any breach notification from Genemod.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					12. Return and Deletion of Customer Data
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					The return and deletion of Customer Data after the
					termination of the Agreement shall be in accordance with
					Annex II Appendix A.
				</TypographyV2>
				<Typography className={styles.ul_title}>
					13. International Data Transfers
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
						13.1 Lawful Transfers. &nbsp;{" "}
					</TypographyV2>
					As part of providing the Genemod Service, Genemod may
					transfer Personal Data to a jurisdiction different from the
					hosting region as necessary for the purposes of complying
					with its obligations under the Agreement. The transfer of
					Personal Data regulated by the GDPR to a jurisdiction
					outside the EEA, or the European Commission-approved
					countries providing ‘adequate’ data protection, shall be
					governed by the Standard Contractual Clauses, attached to
					this DPA to enable the lawful transfer of Personal Data.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					In the event that, for any reason whatsoever, the Standard
					Contractual Clauses no longer constitute an adequate
					safeguard, the parties shall, acting reasonably, promptly
					negotiate in good faith an alternative lawful method to
					facilitate such transfers of Customer Data, taking into
					consideration what other similarly situated business
					partners have done in respect of such issue.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					In case the EU Commission adopts a new set of standard data
					protection clauses for the transfer of Personal Data to
					processors established in third countries which do not
					ensure an adequate level of data protection (Chapter V
					GDPR), the parties shall mutually agree on the incorporation
					of the new set of standard data protection clauses upon
					request.&nbsp;
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
						13.2 “Schrems II” Clause. &nbsp;
					</TypographyV2>
					. Genemod shall promptly notify the Customer if it: (i)
					receives a legally binding request by a public authority
					under the laws of the country of destination for disclosure
					of Personal Data transferred pursuant to this DPA; such
					notification shall include information about the Personal
					Data requested, the requesting authority, the legal basis
					for the request and the response provided; or (ii) becomes
					aware of any direct access by public authorities to Personal
					Information transferred pursuant to this DPA in accordance
					with the laws of the country of destination; such
					notification shall include all information available to the
					importer.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					If Genemod is prohibited from notifying the Customer,
					Genemod agrees to use its best efforts to obtain a waiver of
					the prohibition, with a view to communicate as much
					information and as soon as possible. Genemod agrees to
					document its best efforts in order to be able to demonstrate
					them upon request of the Customer.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					To the extent permissible under the laws of the country of
					destination, Genemod agrees to provide or make available to
					the Customer, in regular intervals for the duration of the
					contract, the greatest possible amount of relevant
					information on the requests received in relation to this DPA
					(in particular, number of requests, type of data requested,
					requesting authority or authorities, whether requests have
					been challenged and the outcome of such challenges, etc.).
				</TypographyV2>
			</section>
			<section>
				<Typography className={styles.appendix}>
					Appendix A EEA Standard Contractual Clauses (Processors)
				</Typography>
				<Typography className={styles.section_title}>
					Section I
				</Typography>
				<Typography className={styles.ul_title2}>
					Clause 1 Purpose and Scope
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
							The purpose of these standard contractual clauses is
							to ensure compliance with the requirements of
							Regulation (EU) 2016/679 of the European Parliament
							and of the Council of 27 April 2016 on the
							protection of natural persons with regard to the
							processing of Personal Information and on the free
							movement of such data (General Data Protection
							Regulation) for the transfer of Personal Data to a
							third country.
					</li>
					<li>
							The Parties:
						<ul className={styles.list_lower_roman}>
							<li>
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
									the natural or legal person(s), public
									authority/ies, agency/ies or other body/ies
									(hereinafter “entity/ies”) transferring the
									Personal Data, as listed in Annex I.A.
									(hereinafter each “data exporter”), and
								</TypographyV2>
							</li>
							<li>
								<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
									the entity/ies in a third country receiving
									the Personal Data from the data exporter,
									directly or indirectly via another entity
									also Party to these Clauses, as listed in
									Annex I.A. (hereinafter each “data
									importer”) have agreed to these standard
									contractual clauses (hereinafter:
									“Clauses”).
								</TypographyV2>
							</li>
						</ul>
					</li>
					<li>
							These Clauses apply with respect to the transfer of
							Personal Data as specified in Annex I.B.
					</li>
					<li>
							The Appendix to these Clauses containing the Annexes
							referred to therein forms an integral part of these
							Clauses.
					</li>
					</TypographyV2>
				</ul>

				<Typography className={styles.ul_title2}>
					Clause 2 Effect and Invariability of the Clauses
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
							These Clauses set out appropriate safeguards,
							including enforceable data subject rights and
							effective legal remedies, pursuant to Article 46(1)
							and Article 46 (2)(c) of Regulation (EU) 2016/679
							and, with respect to data transfers from controllers
							to processors and/or processors to processors,
							standard contractual clauses pursuant to Article
							28(7) of Regulation (EU) 2016/679, provided they are
							not modified, except to select the appropriate
							Module(s) or to add or update information in the
							Appendix. This does not prevent the Parties from
							including the standard contractual clauses laid down
							in these Clauses in a wider contract and/or to add
							other clauses or additional safeguards, provided
							that they do not contradict, directly or indirectly,
							these Clauses or prejudice the fundamental rights or
							freedoms of data subjects.
					</li>
					<li>
							These Clauses are without prejudice to obligations
							to which the data exporter is subject by virtue of
							Regulation (EU) 2016/679.
					</li>
					</TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 3 Third-party Beneficiaries
				</Typography>
				<ul className={styles.list_lower_alpha}>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					<li>
						Data subjects may invoke and enforce these Clauses, as
						third-party beneficiaries, against the data exporter
						and/or data importer, with the following
						<ul className={styles.list_lower_roman}>
							<li>
								Clause 1, Clause 2, Clause 3, Clause 6, Clause
								7;
							</li>
							<li>
								Clause 8 - Module One: Clause 8.5 (e) and Clause
								8.9(b); Module Two: Clause 8.1(b), 8.9(a), (c),
								(d) and (e); Module Three: Clause 8.1(a), (c)
								and (d) and Clause 8.9(a), (c), (d), (e), (f)
								and (g); Module Four: Clause 8.1 (b) and Clause
								8.3(b);
							</li>
							<li>
								Clause 9 - Module Two: Clause 9(a), (c), (d) and
								(e); Module Three: Clause 9(a), (c), (d) and
								(e);
							</li>
							<li>
								Clause 12 - Module One: Clause 12(a) and (d);
								Modules Two and Three: Clause 12(a), (d) and
								(f);
							</li>
							<li>Clause 13;</li>
							<li>
								Clause 15.1(c), (d) and (e);
							</li>
							<li>Clause 16(e);</li>
							<li>
								Clause 18 - Modules One, Two and Three: Clause
								18(a) and (b); Module Four: Clause 18.
							</li>
						</ul>
					</li>
					<li>
						Paragraph (a) is without prejudice to rights of data
						subjects under Regulation (EU) 2016/679.
					</li>
					</TypographyV2>
					
					
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 4 Interpretation
				</Typography>
				<ul
					className={styles.list_lower_alpha}
					style={{ marginLeft: "20px" }}
				>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					<li>
						Where these Clauses use terms that are defined in
						Regulation (EU) 2016/679, those terms shall have the
						same meaning as in that Regulation.
					</li>
					<li>
						These Clauses shall be read and interpreted in the light
						of the provisions of Regulation (EU) 2016/679.
					</li>
					<li>
						These Clauses shall not be interpreted in a way that
						conflicts with rights and obligations provided for in
						Regulation (EU) 2016/679.
					</li>
					</TypographyV2>
					
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 5 Hierarchy
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					In the event of a contradiction between these Clauses and
					the provisions of related agreements between the Parties,
					existing at the time these Clauses are agreed or entered
					into thereafter, these Clauses shall prevail.
				</TypographyV2>
				<Typography className={styles.ul_title2}>
					Clause 6 Description of the Transfer(s)
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					The details of the transfer(s), and in particular the
					categories of Personal Data that are transferred and the
					purpose(s) for which they are transferred, are specified in
					Annex I.B.
				</TypographyV2>
				<Typography className={styles.ul_title2}>
					Clause 7 - Optional Docking Clause
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					- &nbsp; not used &nbsp; -
				</TypographyV2>
				<Typography className={styles.section_title}>
					Section II - Obligations of the Parties
				</Typography>
				<Typography className={styles.ul_title2}>
					Clause 8 Data Protection Safeguards
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					The data exporter warrants that it has used reasonable
					efforts to determine that the data importer is able, through
					the implementation of appropriate technical and
					organisational measures, to satisfy its obligations under
					these Clauses.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.1 Instructions
				</TypographyV2>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
				<li>
						The data importer shall process the personal data only
						on documented instructions from the data exporter. The
						data exporter may give such instructions throughout the
						duration of the contract.
					</li>
					<li>
						The data importer shall immediately inform the data
						exporter if it is unable to follow those instructions.
					</li>
				</TypographyV2>
					
				</ul>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.2 Purpose Limitation
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					The data importer shall process the personal data only for
					the specific purpose(s) of the transfer, as set out in Annex
					I.B, unless on further instructions from the data exporter.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.3 Transparency
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					On request, the data exporter shall make a copy of these
					Clauses, including the Appendix as completed by the Parties,
					available to the data subject free of charge. To the extent
					necessary to protect business secrets or other confidential
					information, including the measures described in Annex II
					and personal data, the data exporter may redact part of the
					text of the Appendix to these Clauses prior to sharing a
					copy, but shall provide a meaningful summary where the data
					subject would otherwise not be able to understand the
					content or exercise his/her rights. On request, the Parties
					shall provide the data subject with the reasons for the
					redactions, to the extent possible without revealing the
					redacted information. This Clause is without prejudice to
					the obligations of the data exporter under Articles 13 and
					14 of Regulation (EU) 2016/679.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.4 Accuracy
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					If the data importer becomes aware that the personal data it
					has received is inaccurate, or has become outdated, it shall
					inform the data exporter without undue delay. In this case,
					the data importer shall cooperate with the data exporter to
					erase or rectify the data.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.5 Duration of Processing and Erasure or Return of Data
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Processing by the data importer shall only take place for
					the duration specified in Annex I.B. After the end of the
					provision of the processing services, the data importer
					shall, at the choice of the data exporter, delete all
					personal data processed on behalf of the data exporter and
					certify to the data exporter that it has done so, or return
					to the data exporter all personal data processed on its
					behalf and delete existing copies. Until the data is deleted
					or returned, the data importer shall continue to ensure
					compliance with these Clauses. In case of local laws
					applicable to the data importer that prohibit return or
					deletion of the personal data, the data importer warrants
					that it will continue to ensure compliance with these
					Clauses and will only process it to the extent and for as
					long as required under that local law. This is without
					prejudice to Clause 14, in particular the requirement for
					the data importer under Clause 14(e) to notify the data
					exporter throughout the duration of the contract if it has
					reason to believe that it is or has become subject to laws
					or practices not in line with the requirements under Clause
					14(a).
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.6 Security of Processing
				</TypographyV2>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
				<li>
						The data importer and, during transmission, also the
						data exporter shall implement appropriate technical and
						organisational measures to ensure the security of the
						data, including protection against a breach of security
						leading to accidental or unlawful destruction, loss,
						alteration, unauthorised disclosure or access to that
						data (hereinafter “personal data breach”). In assessing
						the appropriate level of security, the Parties shall
						take due account of the state of the art, the costs of
						implementation, the nature, scope, context and
						purpose(s) of processing and the risks involved in the
						processing for the data subjects. The Parties shall in
						particular consider having recourse to encryption or
						pseudonymisation, including during transmission, where
						the purpose of processing can be fulfilled in that
						manner. In case of pseudonymisation, the additional
						information for attributing the personal data to a
						specific data subject shall, where possible, remain
						under the exclusive control of the data exporter. In
						complying with its obligations under this paragraph, the
						data importer shall at least implement the technical and
						organisational measures specified in Annex II. The data
						importer shall carry out regular checks to ensure that
						these measures continue to provide an appropriate level
						of security.
					</li>
					<li>
						The data importer shall grant access to the personal
						data to members of its personnel only to the extent
						strictly necessary for the implementation, management
						and monitoring of the contract. It shall ensure that
						persons authorised to process the personal data have
						committed themselves to confidentiality or are under an
						appropriate statutory obligation of confidentiality.
					</li>
					<li>
						In the event of a personal data breach concerning
						personal data processed by the data importer under these
						Clauses, the data importer shall take appropriate
						measures to address the breach, including measures to
						mitigate its adverse effects. The data importer shall
						also notify the data exporter without undue delay after
						having become aware of the breach. Such notification
						shall contain the details of a contact point where more
						information can be obtained, a description of the nature
						of the breach (including, where possible, categories and
						approximate number of data subjects and personal data
						records concerned), its likely consequences and the
						measures taken or proposed to address the breach
						including, where appropriate, measures to mitigate its
						possible adverse effects. Where, and in so far as, it is
						not possible to provide all information at the same
						time, the initial notification shall contain the
						information then available and further information
						shall, as it becomes available, subsequently be provided
						without undue delay.
					</li>
					<li>
						The data importer shall cooperate with and assist the
						data exporter to enable the data exporter to comply with
						its obligations under Regulation (EU) 2016/679, in
						particular to notify the competent supervisory authority
						and the affected data subjects, taking into account the
						nature of processing and the information available to
						the data importer.
					</li>
</TypographyV2>
					
				</ul>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.7 Sensitive Data
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Where the transfer involves personal data revealing racial
					or ethnic origin, political opinions, religious or
					philosophical beliefs, or trade union membership, genetic
					data, or biometric data for the purpose of uniquely
					identifying a natural person, data concerning health or a
					person’s sex life or sexual orientation, or data relating to
					criminal convictions and offences (hereinafter “sensitive
					data”), the data importer shall apply the specific
					restrictions and/or additional safeguards described in Annex
					I.B.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.8 Onward Transfers
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					The data importer shall only disclose the personal data to a
					third party on documented instructions from the data
					exporter. In addition, the data may only be disclosed to a
					third party located outside the European Union (in the same
					country as the data importer or in another third country,
					hereinafter “onward transfer”) if the third party is or
					agrees to be bound by these Clauses, under the appropriate
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">Module, or if:</TypographyV2>
				<ul
					className={styles.list_lower_roman}
					style={{ marginLeft: "26px" }}
				>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						the onward transfer is to a country benefitting from an
						adequacy decision pursuant to Article 45 of Regulation
						(EU) 2016/679 that covers the onward transfer;
					</li>
					<li>
						the third party otherwise ensures appropriate safeguards
						pursuant to Articles 46 or 47 Regulation of (EU)
						2016/679 with respect to the processing in question;
					</li>
					<li>
						the onward transfer is necessary for the establishment,
						exercise or defence of legal claims in the context of
						specific administrative, regulatory or judicial
						proceedings; or
					</li>
					<li>
						the onward transfer is necessary in order to protect the
						vital interests of the data subject or of another
						natural person.
					</li></TypographyV2>
				</ul>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					Any onward transfer is subject to compliance by the data
					importer with all the other safeguards under these Clauses,
					in particular purpose limitation.
				</TypographyV2>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					8.9 Documentation and Compliance
				</TypographyV2>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer shall promptly and adequately deal
						with enquiries from the data exporter that relate to the
						processing under these Clauses.
					</li>
					<li>
						The Parties shall be able to demonstrate compliance with
						these Clauses. In particular, the data importer shall
						keep appropriate documentation on the processing
						activities carried out on behalf of the data exporter.
					</li>
					<li>
						The data importer shall make available to the data
						exporter all information necessary to demonstrate
						compliance with the obligations set out in these Clauses
						and at the data exporter’s request, allow for and
						contribute to audits of the processing activities
						covered by these Clauses, at reasonable intervals or if
						there are indications of non- compliance. In deciding on
						a review or audit, the data exporter may take into
						account relevant certifications held by the data
						importer.
					</li>
					<li>
						The data exporter may choose to conduct the audit by
						itself or mandate an independent auditor. Audits may
						include inspections at the premises or physical
						facilities of the data importer and shall, where
						appropriate, be carried out with reasonable notice.
					</li>
					<li>
						The Parties shall make the information referred to in
						paragraphs (b) and (c), including the results of any
						audits, available to the competent supervisory authority
						on request.
					</li></TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 9 Use of Sub-processors
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer has the data exporter’s general
						authorisation for the engagement of sub-processor(s)
						from an agreed list. The data importer shall
						specifically inform the data exporter in writing of any
						intended changes to that list through the addition or
						replacement of sub- processors at least 30 days in
						advance, thereby giving the data exporter sufficient
						time to be able to object to such changes prior to the
						engagement of the sub-processor(s). The data importer
						shall provide the data exporter with the information
						necessary to enable the data exporter to exercise its
						right to object.
					</li>
					<li>
						Where the data importer engages a sub-processor to carry
						out specific processing activities (on behalf of the
						data exporter), it shall do so by way of a written
						contract that provides for, in substance, the same data
						protection obligations as those binding the data
						importer under these Clauses, including in terms of
						third-party beneficiary rights for data subjects.3 The
						Parties agree that, by complying with this Clause, the
						data importer fulfils its obligations under Clause 8.8.
						The data importer shall ensure that the sub-processor
						complies with the obligations to which the data importer
						is subject pursuant to these Clauses.
					</li>
					<li>
						The data importer shall provide, at the data exporter’s
						request, a copy of such a sub-processor agreement and
						any subsequent amendments to the data exporter. To the
						extent necessary to protect business secrets or other
						confidential information, including personal data, the
						data importer may redact the text of the agreement prior
						to sharing a copy.
					</li>
					<li>
						<span>
							The data importer shall remain fully responsible to
							the data exporter for the performance of the
							sub-processor’s obligations under its contract with
							the data importer. The data importer shall notify
							the data exporter of any failure by the
							sub-processor to fulfil its obligations under that
							contract.
						</span>
					</li>
					<li>
						The data importer shall agree a third-party beneficiary
						clause with the sub-processor whereby - in the event the
						data importer has factually disappeared, ceased to exist
						in law or has become insolvent - the data exporter shall
						have the right to terminate the sub-processor contract
						and to instruct the sub-processor to erase or return the
						personal data.
					</li></TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 10 Data subject rights
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer shall promptly notify the data
						exporter of any request it has received from a data
						subject. It shall not respond to that request itself
						unless it has been authorised to do so by the data
						exporter.
					</li>
					<li>
						The data importer shall assist the data exporter in
						fulfilling its obligations to respond to data subjects’
						requests for the exercise of their rights under
						Regulation (EU) 2016/679. In this regard, the Parties
						shall set out in Annex II the appropriate technical and
						organisational measures, taking into account the nature
						of the processing, by which the assistance shall be
						provided, as well as the scope and the extent of the
						assistance required.
					</li>
					<li>
						In fulfilling its obligations under paragraphs (a) and
						(b), the data importer shall comply with the
						instructions from the data exporter.
					</li>
					</TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 11 Redress
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer shall inform data subjects in a
						transparent and easily accessible format, through
						individual notice or on its website, of a contact point
						authorised to handle complaints. It shall deal promptly
						with any complaints it receives from a data subject.
					</li>
					<li>
						In case of a dispute between a data subject and one of
						the Parties as regards compliance with these Clauses,
						that Party shall use its best efforts to resolve the
						issue amicably in a timely fashion. The Parties shall
						keep each other informed about such disputes and, where
						appropriate, cooperate in resolving them.
					</li>
					<li>
						Where the data subject invokes a third-party beneficiary
						right pursuant to Clause 3, the data importer shall
						accept the decision of the data subject to:
						<ul className={styles.list_lower_roman}>
							<li>
								lodge a complaint with the supervisory authority
								in the Member State of his/her habitual
								residence or place of work, or the competent
								supervisory authority pursuant to Clause 13;
							</li>
							<li>
								refer the dispute to the competent courts within
								the meaning of Clause 18.
							</li>
						</ul>
					</li>
					<li>
						The Parties accept that the data subject may be
						represented by a not-for-profit body, organisation or
						association under the conditions set out in Article
						80(1) of Regulation (EU) 2016/679.
					</li>
					<li>
						The data importer shall abide by a decision that is
						binding under the applicable EU or Member State law.
					</li>
					<li>
						The data importer agrees that the choice made by the
						data subject will not prejudice his/her substantive and
						procedural rights to seek remedies in accordance with
						applicable laws.
					</li></TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 12 Liability
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						Each Party shall be liable to the other Party/ies for
						any damages it causes the other Party/ies by any breach
						of these Clauses.
					</li>
					<li>
						The data importer shall be liable to the data subject,
						and the data subject shall be entitled to receive
						compensation, for any material or non-material damages
						the data importer or its sub-processor causes the data
						subject by breaching the third-party beneficiary rights
						under these Clauses.
					</li>
					<li>
						Notwithstanding paragraph (b), the data exporter shall
						be liable to the data subject, and the data subject
						shall be entitled to receive compensation, for any
						material or non-material damages the data exporter or
						the data importer (or its sub-processor) causes the data
						subject by breaching the third-party beneficiary rights
						under these Clauses. This is without prejudice to the
						liability of the data exporter and, where the data
						exporter is a processor acting on behalf of a
						controller, to the liability of the controller under
						Regulation (EU) 2016/679 or Regulation (EU) 2018/1725,
						as applicable.
					</li>
					<li>
						The Parties agree that if the data exporter is held
						liable under paragraph (c) for damages caused by the
						data importer (or its sub-processor), it shall be
						entitled to claim back from the data importer that part
						of the compensation corresponding to the data importer’s
						responsibility for the damage.
					</li>
					<li>
						Where more than one Party is responsible for any damage
						caused to the data subject as a result of a breach of
						these Clauses, all responsible Parties shall be jointly
						and severally liable and the data subject is entitled to
						bring an action in court against any of these Parties.
					</li>
					<li>
						The Parties agree that if one Party is held liable under
						paragraph (e), it shall be entitled to claim back from
						the other Party/ies that part of the compensation
						corresponding to its / their responsibility for the
						damage.
					</li>
					<li>
						The data importer may not invoke the conduct of a
						sub-processor to avoid its own liability.
					</li></TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 13 Supervision
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						[Where the data exporter is established in an EU Member
						State :] The supervisory authority with responsibility
						for ensuring compliance by the data exporter with
						Regulation (EU) 2016/679 as regards the data transfer,
						as indicated in Annex I.C, shall act as competent
						supervisory authority. <br />
						<br />
						[Where the data exporter is not established in an EU
						Member State, but falls within the territorial scope of
						application of Regulation (EU) 2016/679 in accordance
						with its Article 3(2) and has appointed a representative
						pursuant to Article 27(1) of Regulation (EU) 2016/679:]
						The supervisory authority of the Member State in which
						the representative within the meaning of Article 27(1)
						of Regulation (EU) 2016/679 is established, as indicated
						in Annex I.C, shall act as competent supervisory
						authority. <br />
						<br /> [Where the data exporter is not established in an
						EU Member State, but falls within the territorial scope
						of application of Regulation (EU) 2016/679 in accordance
						with its Article 3(2) without however having to appoint
						a representative pursuant to Article 27(2) of Regulation
						(EU) 2016/679:] The supervisory authority of one of the
						Member States in which the data subjects whose Personal
						Data is transferred under these Clauses in relation to
						the offering of goods or services to them, or whose
						behaviour is monitored, are located, as indicated in
						Annex I.C, shall act as competent supervisory authority.
					</li>
					<li>
						The data importer agrees to submit itself to the
						jurisdiction of and cooperate with the competent
						supervisory authority in any procedures aimed at
						ensuring compliance with these Clauses. In particular,
						the data importer agrees to respond to enquiries, submit
						to audits and comply with the measures adopted by the
						supervisory authority, including remedial and
						compensatory measures. It shall provide the supervisory
						authority with written confirmation that the necessary
						actions have been taken.
					</li></TypographyV2>
				</ul>

				<Typography className={styles.section_title}>
					Section III - Local Laws and Obligations in Case of Access
					by Public Authorities
				</Typography>
				<Typography className={styles.ul_title2}>
					Clause 14 Local Laws and Practices Affecting Compliance with
					the Clauses
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The Parties warrant that they have no reason to believe
						that the laws and practices in the third country of
						destination applicable to the processing of the personal
						data by the data importer, including any requirements to
						disclose personal data or measures authorising access by
						public authorities, prevent the data importer from
						fulfilling its obligations under these Clauses. This is
						based on the understanding that laws and practices that
						respect the essence of the fundamental rights and
						freedoms and do not exceed what is necessary and
						proportionate in a democratic society to safeguard one
						of the objectives listed in Article 23(1) of Regulation
						(EU) 2016/679, are not in contradiction with these
						Clauses.
					</li>
					<li>
						The Parties declare that in providing the warranty in
						paragraph (a), they have taken due account in particular
						of the following elements:
						<ul className={styles.list_lower_roman}>
							<li>
								the specific circumstances of the transfer,
								including the length of the processing chain,
								the number of actors involved and the
								transmission channels used; intended onward
								transfers; the type of recipient; the purpose of
								processing; the categories and format of the
								transferred personal data; the economic sector
								in which the transfer occurs; the storage
								location of the data transferred;
							</li>
							<li>
								the laws and practices of the third country of
								destination– including those requiring the
								disclosure of data to public authorities or
								authorising access by such authorities –
								relevant in light of the specific circumstances
								of the transfer, and the applicable limitations
								and safeguards;
							</li>
							<li>
								any relevant contractual, technical or
								organisational safeguards put in place to
								supplement the safeguards under these Clauses,
								including measures applied during transmission
								and to the processing of the personal data in
								the country of destination.
							</li>
						</ul>
					</li>
					<li>
						The data importer warrants that, in carrying out the
						assessment under paragraph (b), it has made its best
						efforts to provide the data exporter with relevant
						information and agrees that it will continue to
						cooperate with the data exporter in ensuring compliance
						with these Clauses.
					</li>
					<li>
						The Parties agree to document the assessment under
						paragraph (b) and make it available to the competent
						supervisory authority on request.
					</li>
					<li>
						The data importer agrees to notify the data exporter
						promptly if, after having agreed to these Clauses and
						for the duration of the contract, it has reason to
						believe that it is or has become subject to laws or
						practices not in line with the requirements under
						paragraph (a), including following a change in the laws
						of the third country or a measure (such as a disclosure
						request) indicating an application of such laws in
						practice that is not in line with the requirements in
						paragraph (a).
						<br />
						Following a notification pursuant to paragraph (e), or
						if the data exporter otherwise has reason to believe
						that the data importer can no longer fulfil its
						obligations under these Clauses, the data exporter shall
						promptly identify appropriate measures (e.g. technical
						or organisational measures to ensure security and
						confidentiality) to be adopted by the data exporter
						and/or data importer to address the situation. The data
						exporter shall suspend the data transfer if it considers
						that no appropriate safeguards for such transfer can be
						ensured, or if instructed by the competent supervisory
						authority to do so. In this case, the data exporter
						shall be entitled to terminate the contract, insofar as
						it concerns the processing of personal data under these
						Clauses. If the contract involves more than two Parties,
						the data exporter may exercise this right to termination
						only with respect to the relevant Party, unless the
						Parties have agreed otherwise. Where the contract is
						terminated pursuant to this Clause, Clause 16(d) and (e)
						shall apply.
					</li>
					</TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 15 Obligations of the Data Importer in Case of Access
					by Public Authorities
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					15.1 Notification
				</TypographyV2>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer agrees to notify the data exporter
						and, where possible, the data subject promptly (if
						necessary with the help of the data exporter) if it:
						<ul className={styles.list_lower_roman}>
							<li>
								receives a legally binding request from a public
								authority, including judicial authorities, under
								the laws of the country of destination for the
								disclosure of personal data transferred pursuant
								to these Clauses; such notification shall
								include information about the personal data
								requested, the requesting authority, the legal
								basis for the request and the response provided;
								or
							</li>
							<li>
								becomes aware of any direct access by public
								authorities to personal data transferred
								pursuant to these Clauses in accordance with the
								laws of the &nbsp;country of destination; such
								notification shall include all information
								available to the importer.
							</li>
						</ul>
					</li>
					<li>
						If the data importer is prohibited from notifying the
						data exporter and/or the data subject under the laws of
						the country of destination, the data importer agrees to
						use its best efforts to obtain a waiver of the
						prohibition, with a view to communicating as much
						information as possible, as soon as possible. The data
						importer agrees to document its best efforts in order to
						be able to demonstrate them on request of the data
						exporter.
					</li>
					<li>
						Where permissible under the laws of the country of
						destination, the data importer agrees to provide the
						data exporter, at regular intervals for the duration of
						the contract, with as much relevant information as
						possible on the requests received (in particular, number
						of requests, type of data requested, requesting
						authority/ies, whether requests have been challenged and
						the outcome of such challenges, etc.).
					</li>
					<li>
						The data importer agrees to preserve the information
						pursuant to paragraphs (a) to (c) for the duration of
						the contract and make it available to the competent
						supervisory authority on request.
					</li>
					<li>
						Paragraphs (a) to (c) are without prejudice to the
						obligation of the data importer pursuant to Clause 14(e)
						and Clause 16 to inform the data exporter promptly where
						it is unable to comply with these Clauses.
					</li>
					</TypographyV2>
				</ul>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
					15.2 Review of Legality and Data Minimisation
				</TypographyV2>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer agrees to review the legality of the
						request for disclosure, in particular whether it remains
						within the powers granted to the requesting public
						authority, and to challenge the request if, after
						careful assessment, it concludes that there are
						reasonable grounds to consider that the request is
						unlawful under the laws of the country of destination,
						applicable obligations under international law and
						principles of international comity. The data importer
						shall, under the same conditions, pursue possibilities
						of appeal. When challenging a request, the data importer
						shall seek interim measures with a view to suspending
						the effects of the request until the competent judicial
						authority has decided on its merits. It shall not
						disclose the personal data requested until required to
						do so under the applicable procedural rules. These
						requirements are without prejudice to the obligations of
						the data importer under Clause 14(e).
					</li>
					<li>
						The data importer agrees to document its legal
						assessment and any challenge to the request for
						disclosure and, to the extent permissible under the laws
						of the country of destination, make the documentation
						available to the data exporter. It shall also make it
						available to the competent supervisory authority on
						request.
					</li>
					<li>
						The data importer agrees to provide the minimum amount
						of information permissible when responding to a request
						for disclosure, based on a reasonable interpretation of
						the request.
					</li>
					</TypographyV2>
				</ul>
				<Typography className={styles.section_title}>
					Section IV - Final Provisions
				</Typography>
				<Typography className={styles.ul_title2}>
					Clause 16 Non-compliance with the Clauses and Termination
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						The data importer shall promptly inform the data
						exporter if it is unable to comply with these Clauses,
						for whatever reason.
					</li>
					<li>
						In the event that the data importer is in breach of
						these Clauses or unable to comply with these Clauses,
						the data exporter shall suspend the transfer of Personal
						Data to the data importer until compliance is again
						ensured or the contract is terminated. This is without
						prejudice to Clause 14(f).
					</li>
					<li>
						The data exporter shall be entitled to terminate the
						contract, insofar as it concerns the processing of
						personal data under these Clauses, where:
						<ul className={styles.list_lower_roman}>
							<li>
								the data exporter has suspended the transfer of
								personal data to the data importer pursuant to
								paragraph (b) and compliance with these Clauses
								is not restored within a reasonable time and in
								any event within one month of suspension;
							</li>
							<li>
								the data importer is in substantial or
								persistent breach of these Clauses; or
							</li>
							<li>
								the data importer fails to comply with a binding
								decision of a competent court or supervisory
								authority regarding its obligations under these
								Clauses. <br /> In these cases, it shall inform
								the competent supervisory authority of such
								non-compliance. Where the contract involves more
								than two Parties, the data exporter may exercise
								this right to termination only with respect to
								the relevant Party, unless the Parties have
								agreed otherwise.
							</li>
						</ul>
					</li>
					<li>
						Personal data that has been transferred prior to the
						termination of the contract pursuant to paragraph (c)
						shall at the choice of the data exporter immediately be
						returned to the data exporter or deleted in its
						entirety. The same shall apply to any copies of the
						data.] The data importer shall certify the deletion of
						the data to the data exporter. Until the data is deleted
						or returned, the data importer shall continue to ensure
						compliance with these Clauses. In case of local laws
						applicable to the data importer that prohibit the return
						or deletion of the transferred personal data, the data
						importer warrants that it will continue to ensure
						compliance with these Clauses and will only process the
						data to the extent and for as long as required under
						that local law.
					</li>
					<li>
						Either Party may revoke its agreement to be bound by
						these Clauses where (i) the European Commission adopts a
						decision pursuant to Article 45(3) of Regulation (EU)
						2016/679 that covers the transfer of personal data to
						which these Clauses apply; or (ii) Regulation (EU)
						2016/679 becomes part of the legal framework of the
						country to which the personal data is transferred. This
						is without prejudice to other obligations applying to
						the processing in question under Regulation (EU)
						2016/679.
					</li>
					</TypographyV2>
				</ul>
				<Typography className={styles.ul_title2}>
					Clause 17 Governing law
				</Typography>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
					These Clauses shall be governed by the law of the EU Member
					State in which the data exporter is established. Where such
					law does not allow for third-party beneficiary rights, they
					shall be governed by the law of another EU Member State that
					does allow for third-party beneficiary rights. The Parties
					agree that this shall be the law of Luxembourg.
				</TypographyV2>
				<Typography className={styles.ul_title2}>
					Clause 18 Choice of forum and jurisdiction
				</Typography>
				<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

					<li>
						Any dispute arising from these Clauses shall be resolved
						by the courts of an EU Member State.
					</li>
					<li>
						The Parties agree that those shall be the courts of
						Luxembourg ( specify Member State).
					</li>
					<li>
						A data subject may also bring legal proceedings against
						the data exporter and/or data importer before the courts
						of the Member State in which he/she has his/her habitual
						residence.
					</li>
					<li>
						The Parties agree to submit themselves to the
						jurisdiction of such courts.
					</li>
					</TypographyV2>
				</ul>
				<section>
					<Typography className={styles.appendix}>
						Annex I 1 to Appendix A
					</Typography>
					<Typography className={styles.section_title}>
						ANNEX I
					</Typography>
					<Typography className={styles.ul_title2}>
						A. List of Parties
					</Typography>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							Data exporter(s):&nbsp;
						</TypographyV2>
						Customer and its Affiliates who are using the&nbsp;
						Genemod Service (see signature section for further
						details).
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							Data importer(s):&nbsp;
						</TypographyV2>
						Genemod Corporation (See signature section for further
						details).
					</TypographyV2>
					<Typography className={styles.ul_title2}>
						B. Description of Transfer
					</Typography>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
						Categories of data subjects whose personal data is
						transferred
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						Categories of data subjects will include data exporter’s
						Users and personnel of data exporter’s (potential)
						business partners.
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
						Categories of personal data transferred
					</TypographyV2>
					<ul className={styles.list_lower_alpha}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

						<li>
							<span>
								Data exporter’s User data:
							</span>
							<ul className={styles.list_lower_solid}>
								<li>Email address</li>
								<li>First name and last name</li>
								<li>
									Optional: nick name, picture, employee ID,
									phone and/or fax number, legal entity, and
									cost center
								</li>
							</ul>
						</li>
						<li>
							<span>
								Data exporter’s (potential) business partner
								contact data:
							</span>
							<ul className={styles.list_lower_solid}>
								<li>
									Contact email address
								</li>
								<li>Contact first and last name</li>
								<li>Optional: phone and/or fax number</li>
							</ul>
						</li>
						<li>
							Such other Personal Data as the data exporter may
							configure the Genemod Service to collect and to
							process
						</li>
						</TypographyV2>
					</ul>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							Sensitive data transferred &nbsp;
						</TypographyV2>
						(if applicable) and applied restrictions or safeguards
						that fully take into consideration the nature of the
						data and the risks involved, such as for instance strict
						purpose limitation, access restrictions (including
						access only for staff having followed specialised
						training), keeping a record of access to the data,
						restrictions for onward transfers or additional security
						measures.
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						No sensitive data will be transferred by the data
						exporter. 
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							The frequency of the transfer (e.g. whether the data
							is transferred on a one-off or continuous basis).
						</TypographyV2>
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						Data will be transferred on a continuous basis. 
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							Nature of the processing/ purpose(s) of the data
							transfer and further processing
						</TypographyV2>
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						For the provision of the services to the data exporter.
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							The period for which the personal data will be
							retained, or, if that is not possible, the criteria
							used to determine that period.
						</TypographyV2>
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						For as long as the processor is providing the Genemod
						Services to the controller under the Master Subscription
						Agreement and additional time as required by processor’s
						data backup obligations and backup deletion processes.
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary" className={styles.text_bold}>
							For transfers to (sub-)processors, also specify
							subject matter, nature and duration of the
							processing
						</TypographyV2>
					</TypographyV2>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						In the scope of providing the services to controller
						under the Master Subscription Agreement, including
						technical support, processor and its sub-processors may
						need access to or process the Personal Data in order to
						provide the services.
					</TypographyV2>
					<Typography className={styles.ul_title2}>
						C. Competent Supervisory Authority
					</Typography>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						See signature section for further details.&nbsp;
					</TypographyV2>
					<Typography className={styles.appendix}>
						Annex II - Technical and Organisational Measures
						Including Technical and Organisational Measures to
						Ensure the Security of the Data
					</Typography>
					<Typography className={styles.section_title}>
						U.S. Privacy Annex
					</Typography>
					<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
						This U.S. Privacy Annex to the existing Master
						Subscription Agreement (or similarly titled agreement)
						addresses provisions related to U.S. privacy, security,
						breach notification, or other data protection laws
						applicable to Personal Information (defined below),
						including, as applicable, the California Consumer
						Privacy Act (“ <b>CCPA</b> ”), as amended by the California
						Privacy Rights Act &nbsp;(“<b>CPRA</b> ”), and regulations
						promulgated pursuant to such laws.
					</TypographyV2>
					<ul className={styles.list_number}>
				<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">

						<li>
							Definitions. For purposes of this Annex only: (i)
							“Personal Information” means any information within
							Customer Data that Genemod Processes to allow
							Customer to access and use the Genemod Service
							relating, directly or indirectly, to any identified
							or identifiable natural person or household,
							including but not limited to information that
							identifies, relates to, describes, is reasonably
							capable of being associated with, or could
							reasonably be linked, directly or indirectly, with a
							particular natural person or household. Without
							limiting the generality of the foregoing, “Personal
							Information” includes “personal information,”
							“personal data,” and like terms as defined under
							Applicable Data Protection Laws; (ii) “Process” or
							“Processing” means any operation or set of
							operations which is performed on Personal
							Information or on sets of Personal Information,
							whether or not by automated means; (iii) “Applicable
							Law” means any U.S. privacy, security, breach
							notification, or other data protection laws
							applicable to Personal Information, including, as
							applicable, the California Consumer Privacy Act
							(“ <b>CCPA</b> ”), as amended by the California Privacy
							Rights Act (“ <b>CPRA</b> ”), and regulations promulgated
							pursuant to such laws. Capitalized terms not
							otherwise defined in the MSA shall have the meaning
							provided under Applicable Law.
						</li>
						<li>
							To the extent that Genemod collects or Processes
							Personal Information within Customer Data in
							connection with performing functions on behalf of
							Customer specified in the Agreement and further to
							the extent applicable and required by Applicable
							Law, Genemod agrees as follows:
							<ul className={styles.list_lower_alpha}>
								<li>
									Genemod shall use, disclose, or otherwise
									Process the Personal Information only to
									perform functions under the Agreement or as
									otherwise required by law or agreed to in
									writing by Customer. Without limiting the
									generality of the foregoing, and subject to
									the terms and conditions herein, Genemod
									agrees it shall not: (i) Sell or Share the
									Personal Information; (ii) retain, use, or
									disclose the Personal Information for any
									purpose other than for the specific purpose
									of performing functions under the Agreement,
									including retaining, using, or disclosing
									the Personal Information for a commercial
									purpose other than performing functions
									under the Agreement or retaining the
									Personal Information other than in
									connection with functions described in the
									Agreement; (iii) retain, use, or disclose
									the Personal Information outside of the
									direct business relationship between Genemod
									and Customer; or (iv) on or after January 1,
									2023, combine the Personal Information with
									Personal Information it receives from
									another source except as permitted by
									Applicable Law. Notwithstanding the
									foregoing, Customer agrees that Genemod may,
									if otherwise permitted by Applicable Law and
									subject to Genemod’s confidentiality
									obligations hereunder, Process Personal
									Information as permitted under Applicable
									Law, retain Personal Information to the
									extent required by Applicable Law, and
									perform other Processing functions as
									permitted under Applicable Law. Genemod
									hereby certifies that it understands the
									restrictions set forth in this section and
									will comply with them. The parties
									acknowledge and agree that (x) the subject
									matter, nature, and purposes of Processing
									of the Personal Information is Genemod’s
									performance of functions specified in the
									Agreement; and (y) the duration of
									Processing of the Personal Information by
									Genemod is set forth in Genemod’s Privacy
									Policy.&nbsp;
								</li>
								<li>
									Genemod shall comply with Applicable Law and
									reasonably assist Customer to comply with
									Applicable Laws, including but not limited
									to providing reasonable assistance honoring
									individual rights requests as necessary for
									Customer to comply with Applicable Laws. If
									Genemod receives requests relating to
									Personal Information directly from an
									individual in connection with the Agreement,
									Genemod shall inform the individual that
									Genemod cannot respond to the request
									because it is a Service Provider or
									Processor.
								</li>
								<li>
									Genemod shall engage a qualified,
									independent assessor at Genemod’s expense as
									described in the section labeled “Audit
									Report” of the Agreement. Customer
									acknowledges that this provision satisfies
									audit or assessment requirements under
									Applicable Laws.
								</li>
								<li>
									Genemod shall maintain reasonable security
									measures to protect Personal Information in
									accordance with the Agreement, including
									providing at least the same level of privacy
									protection as is required by Applicable Laws
									and ensuring each person Processing the
									Personal Information is subject to a duty of
									confidentiality with respect to such
									Personal Information. If Genemod directly
									causes a breach of Personal Information that
									requires notification under Applicable Law,
									Genemod shall provide reasonable assistance
									to Customer as necessary for Customer to
									comply with Applicable Law.
								</li>
								<li>
									The parties do not intend for Genemod to
									Process Personal Information in a manner
									that presents a heightened risk of harm to
									consumers or that otherwise requires
									Customer to conduct a data protection
									assessment under Applicable Law (“ <b>High Risk Processing</b> ”). To the extent that Customer
									provides advanced written notice to Genemod
									that Genemod’s &nbsp;performance of
									functions under the Agreement may involve
									High Risk Processing, and Genemod agrees to
									perform such High Risk Processing, Genemod
									agrees to provide necessary information to
									enable Customer to conduct and document data
									protection assessments that are required by
									Applicable Law.
								</li>
								<li>
									Customer approves of Genemod’s use of the
									subprocessors listed at: &nbsp;
									<a
										className={styles.link}
										href="https://trust.genemod.net/subprocessors"
									>
										https://trust.genemod.net/subprocessors.
										&nbsp;
									</a>
									Genemod shall provide notice of updates to
									such list by updating the link and Customer
									shall be responsible for periodically
									monitoring such list. If Customer notifies
									Genemod of any objections (on reasonable
									grounds) within 30 days of the list being
									updated, Genemod shall not affect the
									subprocessor update until reasonable steps
									have been taken to address the objections
									raised by Customer.
								</li>
								<li>
									If Genemod can no longer meet its
									obligations under Applicable Law, it shall
									provide Customer notice thereof.
								</li>
							</ul>
						</li>
						</TypographyV2>
					</ul>
				</section>
			</section>
		</div>
	);
};
